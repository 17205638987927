
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import LoanService from "@/services/loan-service";
import FileManagerService from "@/services/file-manager-service";
import { openCameraErrors, prepareCameraErrors } from "@/utils/unicoErrorsMap";

@Component({
  components: {
    Page
  }
})
export default class UnicoCamera extends Vue {
  operationCode: string = "";
  isValidLiveness: boolean = false;
  initializingUnicoCamera: boolean = true;
  livenessFinished: boolean = false;
  cameraOpener: any = null;
  unicoModule: any = null;
  loading: boolean = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  loanService: LoanService;
  fileManagerService: FileManagerService;
  bucket: string;
  filesUrl: string;
  unicoCallback: any;
  documents: {
    url: string;
    type: string;
  }[];

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
    this.fileManagerService = FileManagerService.getInstance();
    this.bucket = process.env.VUE_APP_BORROWER_DOCUMENTS_BUCKET || "";
    this.filesUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  async created() {
    console.log("created");
    this.unicoCallback = {
      on: {
        success: async (obj) => {
          console.log("unicoCallback-success");
          this.loading = true;

          try {
            const base64Image = obj.base64;
            const file = this.convertBase64ToBlob(base64Image, "image/jpeg");
            const formData = new FormData();
            formData.append("file", file, `selfie-${Date.now()}.jpeg`);

            const [uploadError, uploadData] =
              await this.fileManagerService.uploadFile({
                bucket: this.bucket,
                formData
              });

            if (uploadError || !uploadData) {
              this.errorMessage =
                "Erro ao realizar upload da selfie no processo de liveness. Tente novamente.";
              return;
            }

            const [finishLivenessError, finishedLiveness] =
              await this.loanService.finishLiveness({
                operationCode: this.operationCode,
                url: `${uploadData.bucket}/${uploadData.fileKey}`,
                encrypted: obj.encrypted
              });

            if (finishLivenessError) {
              this.errorMessage =
                "Não foi possível verificar a integridade da finalização do liveness. Tente novamente.";
              return;
            }
          } catch (statusError) {
            console.log("unicoCallback-success-statusError", statusError);
            this.errorMessage =
              "Erro ao prosseguir no processo de liveness. Tente novamente.";
            this.$notify({
              title: "Erro",
              text: this.errorMessage,
              type: "error"
            });
            return;
          } finally {
            this.loading = false;
          }

          this.successMessage = "Selfie enviada com sucesso!";
          this.livenessFinished = true;
          this.$notify({
            title: "Validação finalizada",
            text: "Você pode fechar a tela agora.",
            type: "success"
          });
        },
        error: async (error) => {
          console.error("unicoCallback-error", error);
          if (error.code == "73724") {
            console.log("unicoCallback-error-73724");
            this.cameraOpener = null;
            return await this.initializeUnicoCamera();
          }

          this.errorMessage =
            openCameraErrors[error.code] ||
            "Ocorreu um erro inesperado ao abrir a câmera.";

          return this.$notify({
            text: this.errorMessage,
            type: "warning"
          });
        }
      }
    };
  }

  async mounted(): Promise<void> {
    console.log("mounted");
    this.operationCode = this.$route.params.operationCode;
    this.isValidLiveness = await this.checkLiveness();

    if (!this.isValidLiveness) {
      this.errorMessage =
        "Proposta inválida ou não encontrada para verificação.";
      this.livenessFinished = true;
      this.initializingUnicoCamera = false;
    } else {
      await this.setupUnicoCamera();
      this.initializingUnicoCamera = false;
    }
  }

  async setupUnicoCamera() {
    console.log("setupUnicoCamera");
    const {
      UnicoCheckBuilder,
      SelfieCameraTypes,
      UnicoThemeBuilder,
      LocaleTypes,
      SDKEnvironmentTypes,
      UnicoConfig
    } = await import("unico-webframe");

    try {
      const environment =
        process.env.VUE_APP_NODE_ENV === "development" ||
        process.env.VUE_APP_NODE_ENV === "local"
          ? SDKEnvironmentTypes.UAT
          : SDKEnvironmentTypes.PROD;

      console.log("env", process.env.VUE_APP_NODE_ENV);
      console.log(environment, "environment");

      const unicoCssBuilder = await new UnicoThemeBuilder()
        .setColorSilhouetteSuccess("#0384fc")
        .setColorSilhouetteError("#D50000")
        .build();
      console.log("unicoCssBuilder", unicoCssBuilder);

      const unicoCameraBuilder = await new UnicoCheckBuilder()
        .setResourceDirectory("/unico/resources")
        .setModelsPath("/unico/models")
        .setLocale(LocaleTypes.PT_BR)
        .setEnvironment(environment)
        .setTheme(unicoCssBuilder);
      console.log("unicoCameraBuilder", unicoCameraBuilder);

      const unicoCamera = await unicoCameraBuilder.build();
      console.log("unicoCamera", unicoCamera);

      const hostname = process.env.VUE_APP_HOSTNAME || "";
      console.log("hostname", hostname);
      const hostKey = process.env.VUE_APP_HOST_KEY || "";
      console.log("hostkey", hostKey);

      const config = await new UnicoConfig()
        .setHostname(hostname)
        .setHostKey(hostKey);
      console.log("config", config);

      this.cameraOpener = await unicoCamera.prepareSelfieCamera(
        config,
        SelfieCameraTypes.SMART
      );
      console.log("cameraOpener", this.cameraOpener);
    } catch (error: any) {
      console.error("setupUnicoCamera-error", error);
      this.errorMessage =
        prepareCameraErrors[error.code] ||
        "Ocorreu um erro inesperado ao inicializar a câmera.";

      this.$notify({
        text: this.errorMessage,
        type: "warning"
      });
    }
  }

  async openCamera() {
    console.log("openCamera");
    try {
      await this.cameraOpener.open(this.unicoCallback);
    } catch (error: any) {
      console.error("openCamera-error", error);
      throw error;
    }
  }

  async initializeUnicoCamera(): Promise<void> {
    console.log("initializeUnicoCamera");
    this.errorMessage = null;
    this.successMessage = null;
    try {
      if (!this.cameraOpener) {
        console.log("initializeUnicoCamera - !this.cameraOpener");
        await this.setupUnicoCamera();
      }
      await this.openCamera();
    } catch (error: any) {
      console.error("initializeUnicoCamera-error", error);
      this.errorMessage =
        openCameraErrors[error.code] ||
        "Ocorreu um erro inesperado ao abrir a câmera.";

      this.$notify({
        title: "Erro",
        text: this.errorMessage,
        type: "error"
      });
    }
  }

  async checkLiveness(): Promise<boolean> {
    console.log("checkLiveness");
    try {
      const [loanError, loanResponse] = await this.loanService.checkLiveness({
        operationCode: String(this.operationCode)
      });
      console.log("loanResponse", loanResponse);
      console.log("loanError", loanError);

      if (!loanResponse || loanError) {
        this.errorMessage = "Liveness não encontrado.";
        return false;
      }

      if (!loanResponse?.isReadyForLiveness) {
        this.errorMessage = "Liveness não disponível.";
        return false;
      }

      return true;
    } catch (loanError) {
      console.error("Erro ao encontrar empréstimo:", loanError);
      this.errorMessage = "Erro ao procurar o empréstimo. Tente novamente.";
      return false;
    }
  }

  get baseUrl(): string {
    return process.env.VUE_APP_FILE_MANAGER_URL;
  }

  convertBase64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: mimeType });
  }
}
