export const prepareCameraErrors = Object.freeze({
    73000: "Ocorreu um erro inesperado. Tente novamente mais tarde.",
    73001: "Informação obrigatória ausente.",
    73002: "Formato inválido. Verifique os dados inseridos.",
    73003: "Não foi possível processar a solicitação.",
    73004: "Erro interno ao processar a requisição.",
    73005: "Idioma não suportado.",
    73006: "Texto não encontrado.",
    73100: "Sem conexão com a internet. Verifique sua rede.",
    73200: "Erro ao carregar a configuração do sistema.",
    73201: "Erro ao processar os arquivos de configuração.",
    73202: "Configuração inválida. Entre em contato com o suporte.",
    73204: "Falha ao iniciar. Verifique a configuração do ambiente.",
    73300: "Falha na autenticação do aplicativo.",
    73301: "Falha na autenticação do aplicativo.",
    73302: "Token de autenticação ausente.",
    73303: "Dispositivo não autorizado.",
    73400: "Não foi possível iniciar a câmera.",
    73401: "Erro ao carregar modelos para a câmera.",
    73402: "Erro de conexão ao validar a câmera.",
    73403: "Dispositivo ou navegador não compatível.",
    73404: "A câmera só pode ser usada no modo retrato.",
    73405: "Dispositivo bloqueado por tentativas falhas.",
    73406: "A sessão foi cancelada devido a restrições do navegador.",
    73407: "Erro ao carregar o SDK.",
    73500: "Erro ao recuperar sessão.",
    73501: "Erro ao recuperar sessão.",
    73502: "Sessão expirada ou inválida.",
    73600: "Recurso da câmera não encontrado.",
    73601: "Erro ao iniciar a câmera no ambiente de produção.",
    74000: "Código inválido.",
    74001: "Tema inválido.",
  });
  

  export const openCameraErrors = {
    73700: "Erro ao processar a resposta da câmera.",
    73704: "Você cancelou a sessão antes da conclusão.",
    73706: "Acesso à câmera negado. Verifique as permissões do dispositivo.",
    73707: "A sessão foi encerrada devido à interrupção do aplicativo.",
    73708: "A sessão foi cancelada porque o dispositivo está no modo paisagem. Use o modo retrato.",
    73710: "A sessão expirou devido ao tempo limite.",
    73715: "A sessão foi cancelada porque nem todas as imagens-guia foram configuradas.",
    73716: "Erro ao iniciar a câmera neste dispositivo.",
    73717: "A sessão foi cancelada porque o usuário está bloqueado.",
    73718: "Erro inesperado ao executar a sessão. Tente novamente.",
    73720: "A sessão foi encerrada pelo aplicativo.",
    73721: "A sessão foi cancelada devido à mudança de orientação do dispositivo.",
    73722: "A sessão foi cancelada porque o documento não estava pronto.",
    73723: "A sessão foi cancelada porque já havia outra sessão em andamento.",
    73724: "A sessão foi cancelada porque a câmera foi aberta em um iFrame.",
    73728: "Erro de conexão. Certifique-se de estar usando HTTPS.",
    73729: "Navegador não suportado. Tente abrir em outro navegador.",
    73730: "Erro inesperado na licença para iniciar a sessão de liveness.",
    73731: "Não foi possível iniciar a sessão de liveness porque a licença expirou.",
    73732: "Não foi possível iniciar a sessão de liveness porque a origem não é permitida.",
    73800: "Erro ao criptografar a resposta.",
    73900: "Não foi possível obter informações do sistema.",
  };
  