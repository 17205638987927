/* eslint-disable no-use-before-define */
import GlobalParams from "@/types/globalParams";
import HttpClient, { Response } from "./http-client";
import axios from "axios";

export default class FileManagerService extends HttpClient {
  private static classInstance?: FileManagerService;

  private constructor() {
    super(process.env.VUE_APP_FILE_MANAGER_URL!);
  }

  public static getInstance(): FileManagerService {
    if (!this.classInstance) {
      this.classInstance = new FileManagerService();
    }

    return this.classInstance;
  }

  public async uploadFile({
    bucket,
    formData
  }: UploadFileData): Promise<Response<UploadFileResponse>> {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_FILE_MANAGER_URL}/upload/${bucket}`,
        formData
      );
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  }
}

interface UploadFileData {
  bucket: string;
  formData: FormData;
}

interface UploadFileResponse {
  fileKey: string;
  bucket: string;
}

interface InternalSimulateProposalData {
  bankingId: number;
  loanType: string;
  amount: number;
  installments: number;
  negotiatedFee: number;
  withInsurance: boolean;
  marginBaseRegisterId: number;
}

export interface SimulateProposalResponse {
  insuranceValue: number;
  tacValue: number;
  iofPercentual: number;
  iofValue: number;
  billetValue: number;
  requestedValue: number;
  creditAmount: number;
  debitAmount: number;
  installmentsTotal: number;
  installmentsValue: number;
  installments: ProposalInstallment[];
  monthlyFeePercentual: number;
  annualFeePercentual: number;
  monthlyCETPercentual: number;
  annualCETPercentual: number;
}

interface IncludeProposalData {
  operationCode: string;
  document: string;
  amount: string;
  installments: number;
  bank: {
    bankCode: string;
    accountType: string;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
  };
  withInsurance: boolean;
  loanType: string;
}

export interface IncludeProposalResponse {
  proposalCode: string;
}

interface SaveLegalPersonData {
  document: string;
  personal: {
    name: string;
    phone: string;
    birthday: string;
    rg: string;
    rgState: string;
    rgIssuer: string;
    birthCity: string;
    birthState: string;
    maritalStatus: string;
    admissionDate: string;
  };
  /** Required when banking is MoneyPlus */
  segmentId?: number;
}

interface SaveLegalPersonResponse {
  legalPersonCode: string;
}

interface SaveLegalPersonAddressData {
  document: string;
  address: {
    postalCode: string;
    street: string;
    number: string;
    district: string;
    city: string;
    stateAbbreviation: string;
    complement: string;
  };
  /** Required when banking is MoneyPlus */
  segmentId?: number;
}

interface UpdateGlobalParamsData {
  segmentTypeId: number;
  globalParamsList: GlobalParams[];
}

interface SaveLegalPersonAddressResponse {
  legalPersonAddressCode: string;
}

interface ProposalInstallment {
  number: number;
  value: number;
  fee: number;
  debitBalance: number;
  dueDate: string; // utc
}
